import * as React from "react";
import { FunctionComponent } from "react";
import SubmissionSuccessPage from "../templates/SubmissionSuccess/SubmissionSuccess";

const SurepayCancellationSuccess: FunctionComponent = (): JSX.Element => (
  <SubmissionSuccessPage
    heading="SurePay Cancellation Submitted"
    content="Thank you. Your SurePay cancellation form has been submitted. You will receive an email to confirm your cancellation request."
  />
);

export default SurepayCancellationSuccess;
