import "./SubmissionSuccess.css";
import * as React from "react";
import { FunctionComponent } from "react";
import { Button, Heading, Page } from "../../components";
import * as PropTypes from "prop-types";

interface SubmissionSuccessPageProps {
  heading: string;
  content: string;
}

const SubmissionSuccessPage: FunctionComponent<SubmissionSuccessPageProps> = (
  props
): JSX.Element => {
  const { heading, content } = props;

  return (
    <Page title="Successful Submission" layout="default">
      <div className="submission-success__hero">
        <div className="submissions-success__content">
          <Heading level={1}>{heading}</Heading>
          <div className="submission-success__text">{content}</div>
          <Button href="/">Back to Home</Button>
        </div>
      </div>
    </Page>
  );
};

SubmissionSuccessPage.propTypes = {
  heading: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

export default SubmissionSuccessPage;
